import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "jifen-page" }
const _hoisted_2 = { class: "content scroll-bar-hidden" }
const _hoisted_3 = { class: "footer" }
const _hoisted_4 = { class: "surplus" }
const _hoisted_5 = { class: "send" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_8 = { class: "checked op" }
const _hoisted_9 = { class: "notchecked op" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_timetable_title = _resolveComponent("timetable-title")!
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_timetable_checkbox = _resolveComponent("timetable-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_timetable_title, null, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("奖励积分卡")
        ])),
        _: 1
      }),
      _createVNode(_component_close_icon)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.studentList, (i, k) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "card",
          key: i.id
        }, [
          _createVNode(_component_a_avatar, {
            src: _ctx.getAvatar(i),
            size: 117,
            style: {"background-color":"#FFF","border":"6px solid rgba(250, 212, 88, .5)"}
          }, null, 8, ["src"]),
          _createVNode(_component_timetable_checkbox, {
            value: i.checked,
            "onUpdate:value": ($event: any) => ((i.checked) = $event)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(i.name), 1)
            ]),
            _: 2
          }, 1032, ["value", "onUpdate:value"])
        ])), [
          [_vShow, !_ctx.isSend]
        ])
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sendResultArr, (i, k) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "carded",
          key: k,
          style: _normalizeStyle({background: `url(${i.ipUrl?i.ipUrl: 'http://dwart-ats2.oss-cn-zhangjiakou.aliyuncs.com/zsyy/20210420/20210420172815_ibel_3c1e8b30-c6ae-4a96-8fc3-3708141906c8.png'}) no-repeat`, backgroundSize: '100% 100%'})
        }, [
          _createElementVNode("div", null, _toDisplayString(i.name), 1),
          _createElementVNode("div", null, _toDisplayString(i.integral) + "积分", 1)
        ], 4)), [
          [_vShow, _ctx.isSend]
        ])
      }), 128))
    ]),
    _withDirectives(_createElementVNode("div", { class: "tip" }, _toDisplayString(_ctx.sendResultRemark), 513), [
      [_vShow, _ctx.isSend]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, " 本讲剩余可发" + _toDisplayString(_ctx.surplus) + "积分 ", 1),
      _withDirectives(_createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sendList, (i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i.ruleConstantId,
            class: "send-item",
            onClick: ($event: any) => (_ctx.onSend(i))
          }, _toDisplayString(i.integralOperation) + "积分卡 ", 9, _hoisted_6))
        }), 128))
      ], 512), [
        [_vShow, !_ctx.isSend]
      ]),
      _withDirectives(_createElementVNode("div", {
        class: "goon",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onGoonSend && _ctx.onGoonSend(...args)))
      }, " 继续发积分卡 ", 512), [
        [_vShow, _ctx.isSend]
      ]),
      _createElementVNode("div", _hoisted_7, [
        _withDirectives(_createElementVNode("div", {
          class: "check",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onCheckAll && _ctx.onCheckAll(...args)))
        }, [
          _withDirectives(_createElementVNode("div", _hoisted_8, null, 512), [
            [_vShow, _ctx.checkAll]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_9, null, 512), [
            [_vShow, !_ctx.checkAll]
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("div", { style: {"margin-left":"7px","user-select":"none"} }, "全选", -1))
        ], 512), [
          [_vShow, !_ctx.isSend]
        ]),
        _createElementVNode("div", {
          class: "lesson",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onRouterBack && _ctx.onRouterBack(...args)))
        }, " 进入课件 ")
      ])
    ])
  ]))
}