import {useRoute, useRouter} from "vue-router";
import {reactive, toRefs, watch} from "vue";
import {EGlobalSex} from "@/utils/global/globalEnum";
import {message} from "ant-design-vue";
import service from "@/utils/axios";
import _ from 'lodash';
import {onPageLoadingEnd, onPageLoadingStart} from "@/utils/DLoading";

export interface IJifenSendItem {
  integralOperation: number;                         // 发放积分的数额
  ruleConstantId?: any;                               // 积分id
  ruleName: string;
}

export interface IJifenStd {
  avatar: string | null;                  // 学员头像
  name: string;                           // 学员名称
  checked: boolean;                       // 是否发放
  id: number;                             // 学员id
  sex: number;                        // 学员性别
  classCheckId: number;               //  班级考勤id
}

export interface ISendResult {
  name: string;
  integral: number;
  ipUrl: string;
}

export interface IJifenState {
  surplus: number;                        // 该老师剩余积分
  sendList: Array<IJifenSendItem>;        // 可发放积分的数组
  studentList: Array<IJifenStd>;          // 可发放积分的学员数组
  checkAll: boolean;                      // 是否全选
  isSend: boolean;                        // 是否发放
  sendResultArr: Array<ISendResult>;              // 发放的结果数组
  sendResultRemark: string | null;         // 发放结果备注
}

export function jifenPageBill() {
  const router = useRouter();
  const route = useRoute();

  let stateRef = reactive(<IJifenState>{
    surplus: 0,
    sendList: [],
    studentList: [],
    checkAll: false,
    isSend: false,
    sendResultArr: [],
    sendResultRemark: null,
  });

  let refData = toRefs(stateRef)

  let onCheckAll = () => {
    stateRef.checkAll = !stateRef.checkAll;
    stateRef.studentList.forEach(v => {
      v.checked = stateRef.checkAll
    })
  }

  let getAvatar = (item: IJifenStd): string => {
    if (item.avatar && item.avatar.length > 0) {
      return item.avatar
    }
    if (item.sex === EGlobalSex.boy) {
      return require('@/assets/image/sys/boy_default_avatar.png')
    } else {
      return require('@/assets/image/sys/girl_default_avatar.png')
    }
  }

  let onSend = async(item: IJifenSendItem) => {
    // stateRef.isSend = true;
    let checkedArr = stateRef.studentList.filter(v => v.checked);
    if (checkedArr.length === 0) {
      message.error('请选择要发放的学员');
      return;
    }

    onPageLoadingStart('积分发放中, 请稍后...');
    let data: any[] = [];
    stateRef.studentList.forEach(v =>{
      if (v.checked){
        data.push({
          studentId: v.id,
          classId: route.query.classId,
          classScheduleId: route.query.scheduleId,
          classCheckId: v.classCheckId,
          studentName: v.name,
          courseScheduleId: null,
          ruleConstantId: item.ruleConstantId,
          integralCount: item.integralOperation,
        })
      }
    })
    const r:any = await service.post(`/dwart/an_teaching/classCourse/bonusPoints/${route.query.scheduleId}/and/${4}`, data);
    await getStudentAndMax()

    if (r.result){
      onPageLoadingEnd(r.message)
      stateRef.sendResultRemark = null;
    }else{
      onPageLoadingEnd();
      stateRef.sendResultRemark = r.message;
    }
    stateRef.isSend = true;
    stateRef.sendResultArr = r.grantStudents.map(v=>{
      return {
        name: v.studentName,
        integral: v.integralCount,
        ipUrl: v.avatar,
      }
    })
  }

  let onGoonSend = async () => {
    stateRef.studentList.forEach(v => v.checked = false);
    stateRef.isSend = false;
  }

  let onRouterBack = async => {
    router.back();
  }
  const init = async () => {
    await getRules()
    await getStudentAndMax()
  }
  const getRules = async () => {
    const r: any[] = await service.get(`/dwart/an_teaching/classCourse/selectIntegralRule/${route.query.classId}`)
    stateRef.sendList = r.find(v => v.integralRuleName === '积分大卡奖励').dwRuleDtoList;
  }

  const getStudentAndMax = async () => {
    const r: any = await service.get(`/dwart/an_teaching/classCourse/findAttendanceStudents/${route.query.scheduleId}`);
    stateRef.studentList = r.students.map(v => {
      return {
        name: v.studentName,
        avatar: v.avatar,
        checked: false,
        id: v.studentId,
        sex: v.sex,
        classCheckId: v.classCheckId,
      }
    });
    stateRef.surplus = r.thisSurplusIntegral;
  }

  // watch(()=>stateRef.studentList, value => {
  //   console.log(value);
  //   stateRef.checkAll = value.filter(v => v.checked).length === stateRef.studentList.length;
  // }, {deep: true})

  watch(()=>_.cloneDeep(stateRef.studentList), value => {
    stateRef.checkAll = value.filter(v => v.checked).length === stateRef.studentList.length;
  })

  return {
    refData,
    onCheckAll,
    getAvatar,
    onSend,
    onGoonSend,
    onRouterBack,
    init,
    EGlobalSex,
  }
}
