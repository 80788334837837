
import TimetableTitle from "@/components/timetable/TimetableTitle.vue";
import CloseIcon from "@/components/system/CloseIcon.vue";
import {jifenPageBill} from "@/views/timetable/myTimetable/JifenPage/JifenPage";
import {Avatar} from "ant-design-vue";
import TimetableCheckbox from "@/components/timetable/TimetableCheckbox.vue";
import {defineComponent, onMounted, watch} from 'vue'

export default defineComponent({
  name: "JifenPage",
  components: {
    TimetableCheckbox,
    CloseIcon,
    TimetableTitle,
    AAvatar: Avatar,
  },
  setup() {
    const {
      refData,
      onCheckAll,
      getAvatar,
      onSend,
      onGoonSend,
      onRouterBack,
      init,
    } = jifenPageBill();

    onMounted(()=>{
      init()
    })
    return {
      ...refData,
      onCheckAll,
      getAvatar,
      onSend,
      onGoonSend,
      onRouterBack,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
